<template>
	<!-- EHP list -->
	<b-row :id="'accordian'" cols="5" class="list_area ehpAccrodi" :style="{ width: '100%' }">
		<b-col
			class="list w-20"
			v-for="(equip, index) in equipList"
			:key="index"
			:class="'list' + index"
			:style="{ border: '1px solid #313131', margin: '5px 16px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }"
		>
			<ul class="list_info" :style="{ justifyContent: 'space-between' }">
				<transition>
					<li class="icon">
						<!-- <img src="@assets/default/icon/light_on.png" width="50" alt="light_on"> -->
						<img src="@assets/default/icon/light_off.png" width="50" alt="light_off" />
					</li>
				</transition>
				<transition>
					<li class="tit">
						{{ equip.equipName }}
					</li>
				</transition>

				<transition class="list" :class="'ONOFF' + index">
					<transition>
						<li class="btn_control">
							<button
								@click="onSaveOnOff($event.target, ctrPtAddr[index], index)"
								v-if="!liveData[equip.ctrPtAddr] ? 0 : liveData[equip.ctrPtAddr].ptVal ?? 0 == 0"
								type="button"
								class="off"
								value="0"
							>
								off
							</button>
							<button @click="onSaveOnOff($event.target, ctrPtAddr[index], index)" v-else type="button" class="on" value="1">
								on
							</button>
						</li>
					</transition>
				</transition>
				<div class="more"></div>
			</ul>
		</b-col>
	</b-row>
</template>

<script>
	import backEndApi from "@api/backEndApi";
	import config from "@libs/config.js";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	// import mqtt from "@src/api/mqttService"

	export default {
		components: {},
		props: ["zoneInfo", "equipList", "equipListDetail", "ctrPtAddr", "ctrlPropList", "isShow"],
		data() {
			return {
				propList: null,
				ONOFF: [],
				TEMP: [],
				xemsHosts: config.backend.apiUrl,
				popupMessages,
				lightStatus: false,
			};
		},
		computed: {
			liveData() {
				return this.$store.getters.getTotalReal ?? {};
			},
		},
		watch: {
			equipListDetail() {
				this.propList = this.equipListDetail;
			},
			liveData() {
				console.log(this.$store.getters.getTotalReal);
				this.initOnOff();
			},
		},
		created() {
			// mqtt.subscribe("xems/2.1/#", function(err) {
			// 	if(!err) {
			// 		mqtt.on("message", function(topic, message) {
			// 			console.log(topic);
			// 			console.log(message.toString());
			// 		})
			// 	}
			// })
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				this.propList = this.equipListDetail;
				this.initOnOff();
			},
			initOnOff() {
				this.ONOFF = [];
				for (let i = 0; i < this.equipList.length; i++) {
					let equip = this.equipList[i];

					if (equip.propList.length !== 0) {
						for (let j = 0; j < equip.propList.length; j++) {
							let prop = equip.propList[j];
							if (prop.equipPropCode == "ONOFF") {
								this.ONOFF.push({
									ctrlPropYn: prop.ctrlPropYn,
									ptAddr: prop.ptAddr,
									ptVal: this.liveData[prop.ptAddr] === undefined ? prop.ptVal : this.liveData[prop.ptAddr].ptVal,
								});
							}
						}
					} else {
						this.ONOFF.push({
							ctrlPropYn: "N",
							ptAddr: "",
							ptVal: 0,
						});
					}

					let target = document.getElementsByClassName("btn_control")[i]?.children[0];

					if (target) {
						if (Number(this.ONOFF[i].ptVal) === 0) {
							target.textContent = "off";
							target.classList.remove("on");
							target.classList.add("off");
						} else {
							target.textContent = "on";
							target.classList.remove("off");
							target.classList.add("on");
						}
					}
				}

				if (this.ONOFF.length <= 0) {
					for (let i = 0; i < this.ctrlPropList.length; i++) {
						let prop = this.ctrlPropList[i];

						if (prop.propCode == "ONOFF") {
							this.ONOFF.push({
								ctrlPropYn: prop.ctrlPropYn,
								ptAddr: prop.ptAddr,
								ptVal: this.liveData[prop.ptAddr] === undefined ? prop.ptVal : this.liveData[prop.ptAddr].ptVal,
							});
						} else {
							this.ONOFF.push({
								ctrlPropYn: "N",
								ptAddr: "",
								ptVal: 0,
							});
						}

						let target = document.getElementsByClassName("btn_control")[i]?.children[0];

						if (target) {
							if (Number(this.ONOFF[i].ptVal) === 0) {
								target.textContent = "off";
								target.classList.remove("on");
								target.classList.add("off");
							} else {
								target.textContent = "on";
								target.classList.remove("off");
								target.classList.add("on");
							}
						}
					}
				}

				console.log(this.ONOFF);
			},

			async onSaveOnOff(target, ctrPtAddr, idx) {
				console.log("taget : ", target);
				console.log("ctrPtAddr : ", ctrPtAddr);
				console.log("idx : ", idx);
				if (target.textContent == "on") {
					this.ONOFF[idx].ptVal = 0;
				} else {
					this.ONOFF[idx].ptVal = 1;
				}

				let param = {
					ctrPtAddr: ctrPtAddr,
					propList: [],
				};

				for (let i = 0; i < this.ctrlPropList.length; i++) {
					if (this.ctrlPropList[i].propCode === "ONOFF") {
						if (this[this.ctrlPropList[i].propCode][idx].ctrlPropYn === "Y") {
							param.propList.push({
								ptAddr: this[this.ctrlPropList[i].propCode][idx].ptAddr,
								ptVal: this[this.ctrlPropList[i].propCode][idx].ptVal,
							});
							break;
						}
					}
				}

				try {
					if (param.propList.length <= 0) return;

					let result = await backEndApi.outputLog.equipContrl(param);
					this.alertNoti(result.data.message);
					if (this.ONOFF[idx].ptVal === 0) {
						target.textContent = "off";
						target.classList.remove("on");
						target.classList.add("off");
					} else {
						target.textContent = "on";
						target.classList.remove("off");
						target.classList.add("on");
					}
					this.$store.commit("EQUIP_INFO_INSERT", this.ONOFF[idx].ptAddr);
					// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.ONOFF[idx]);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();

					if (this.ONOFF[idx].ptVal === 0) {
						this.ONOFF[idx].ptVal = 1;
					} else {
						this.ONOFF[idx].ptVal = 0;
					}
				}
			},
		},
	};
</script>

<style scoped>
	.fade-enter {
		opacity: 0;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 1s ease-out;
	}
	.fade-leave-to {
		opacity: 0;
	}
</style>
